export const Rentals = {
  main: [
    {
      title: "Mud Systems",
      description: "",
      style: {
        backgroundImage: `url(${require("@/assets/data/premix-tank-with-wind-walls-folded-down.jpg")})`,
        backgroundPosition: "left center"
      },
      bullets: [
        "Divided Compartments",
        "Flow Shakers Built In",
        "Desander Included",
        "Multiple Pump Rooms"
      ]
    },
    {
      title: "Vertical Dryer",
      description: "",
      style: {
        backgroundImage: `url(${require("@/assets/data/vertical-dryer-front-view.jpg")})`,
        backgroundPosition: "left"
      },
      bullets: [
        "Fast Rig Up/Down",
        "Single Truck Move",
        "No Cranes Needed",
        "Reduces Reagent Usage"
      ]
    },
    {
      title: "Dewatering Units",
      description: "",
      style: {
        backgroundImage: `url(${require("@/assets/data/catch-tank-view-of-surface-job.jpg")})`,
        backgroundPosition: "left center"
      },
      bullets: [
        "Custom Injection Manifold",
        "Chemical Storage",
        "Tools & Spare Part Storage",
        "Designated Office"
      ]
    }
  ],
  secondary: []
};
