<template>
  <div class="card">
    <div class="card-img-top" v-if="item.style" :style="item.style"></div>
    <div class="card-header bg-light" v-if="item.title">
      <h2>{{ item.title }}</h2>
    </div>
    <div class="card-body" v-if="item.description">
      <p class="card-text">{{ item.description }}</p>
    </div>
    <div v-if="bulletHeader" class="card-header bg-dark text-light">
      {{ bulletHeader }}
    </div>
    <ul
      class="list-group list-group-flush"
      v-if="item.bullets && item.bullets.length"
    >
      <li
        v-for="(bullet, b) in item.bullets"
        :key="'product-highlight-' + b"
        class="list-group-item"
      >
        {{ bullet }}
      </li>
    </ul>
<!--    <div class="card-footer text-center">-->
<!--      <a href="#" class="btn btn-secondary btn-block btn-lg" v-if="item.title">-->
<!--        <span class="sr-only">Read </span>-->
<!--        More Information-->
<!--        <span class="sr-only">about {{ item.title }}</span>-->
<!--      </a>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({ title: "", description: "", bullets: [] })
    },
    bulletHeader: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped></style>
