export const Services = {
  main: [
    {
      title: "Dewatering",
      description:
        "Dewatering solutions available for almost any kind of project.",
      style: {
        backgroundImage: `url(${require("@/assets/data/dewatering-result-clean.png")})`
      },
      bullets: [
        "Centrifuges & Stands",
        "Dewatering Unit",
        "Catch Tanks",
        "Upright Tanks"
      ]
    },
    {
      title: "Closed Loop Systems",
      description:
        "Systems built to handle all types of drilling fluid and quickly adapt to changes throughout the drilling process.",
      style: {
        backgroundImage: `url(${require("@/assets/data/twin-centrifuge-stand-on-three-sided-tank.jpg")})`
      },
      bullets: ["Centrifuges & Stands", "Catch Tanks", "Loader", "Excavator"]
    },
    {
      title: "Surface Drilling",
      description:
        "Capable of processing large volumes of fluid and require a minimal amount of rig up & down time.",
      style: {
        backgroundImage: `url(${require("@/assets/data/38000-centrifuge-on-stand.jpg")})`
      },
      bullets: [
        "Centrifuges & Stands",
        "Dewatering Unit",
        "Catch Tanks",
        "Loader"
      ]
    },
    {
      title: "Reserve Pit Reclamation",
      description:
        "Fluid Pro can handle the processing of residual fluids from reserve pits, along with the complete reclamation process.",
      style: {
        backgroundImage: `url(${require("@/assets/data/twin-centrifuge-stand-on-three-sided-tank.jpg")})`
      },
      bullets: [
        "Centrifuges & Stands",
        "Catch Tanks",
        "Loader",
        "Filter Trailer"
      ]
    },
    {
      title: "Wastewater Pretreatment",
      description:
        "Fluid Pro can set up a custom solution to pretreat industrial wastewater and process sludge",
      bullets: [
        "Centrifuges & Stands",
        "Catch Tanks",
        "Loader",
        "Filter Trailer"
      ]
    }
  ],
  secondary: {
    title: "Versatility",
    description:
      "Our experience and equipment allow us to be more versatile when it comes to dewatering services.",
    items: [
      {
        title: "Reserve Pit Reclamation",
        link: ""
      },
      {
        title: "Waste-water Pretreatment & Sludge Processing",
        link: ""
      },
      {
        title: "Utility & Pipeline Boring Fluid Management",
        link: ""
      },
      {
        title: "Settling Pond & Lagoon Clean Up",
        link: ""
      }
    ]
  }
};
