import Vue from "vue";
import Vuex from "vuex";
import { Services } from "./store/services";
import { Rentals } from "./store/rentals";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Services,
    Rentals
  },
  mutations: {},
  actions: {}
});
