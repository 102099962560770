<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <div class="col-sm-12 col-md-2 m-auto">
        <div class="card text-center bg-dark text-light">
          <div class="card-header bg-light">
            <h1 class="text-primary">Login</h1>
          </div>
          <div class="card-body">
            <form @submit.prevent="login">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="username">
                    Username
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  v-model="username"
                />
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="password">
                    Password
                  </label>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="password"
                />
              </div>
              <div class="alert alert-danger" v-if="error">{{ error }}</div>
              <button type="submit" class="btn btn-light">Submit</button>
            </form>
          </div>
          <div class="card-footer text-muted bg-light">
            <router-link to="/login/forgot">Forgot Password</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as auth from "firebase/auth";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: ""
    };
  },
  methods: {
    login() {
      const usernameInvalid =
        !this.username.length ||
        !this.username.indexOf("@") ||
        !this.username.indexOf(".");
      const passwordInvalid =
        !this.password.length || !this.password.length < 6;
      this.error = "";

      if (usernameInvalid || passwordInvalid) {
        this.error = "Please enter a username/password.";
      }
    }
  }
};
</script>

<style scoped>
.vh-100 {
  height: calc(100vh - 55px) !important;
}
</style>
