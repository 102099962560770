import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as firebase from "firebase/app";
import "bootstrap";

if (process.env.NODE_ENV === "development") {
  firebase.initializeApp(require("./config/firebase.json"));
} else {
  firebase.initializeApp("/__/firebase/init.json");
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
