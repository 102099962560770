<template>
  <div class="collapse navbar-collapse" id="mainMenu">
    <ul
      v-if="leftSide && leftSide.length"
      class="navbar-nav mr-auto mt-2 mt-lg-0"
    >
      <li
        v-for="(link, linkIndex) in leftSide"
        :key="'top-link-left-' + linkIndex"
        class="nav-item"
      >
        <router-link
          :to="link.url"
          class="nav-link"
          exact-active-class="active"
          exact
        >
          {{ link.name }}
          <span class="sr-only" v-if="fullPath === link.url">(current)</span>
        </router-link>
      </li>
    </ul>
    <ul
      v-if="rightSide && rightSide.length"
      class="navbar-nav ml-auto mt-2 mt-lg-0"
    >
      <li
        v-for="(link, linkIndex) in rightSide"
        :key="'top-link-right-' + linkIndex"
        class="nav-item"
      >
        <router-link
          :to="link.url"
          class="nav-link"
          exact-active-class="active"
          exact
        >
          {{ link.name }}
          <span class="sr-only" v-if="fullPath === link.url">(current)</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Links",
  props: {
    leftSide: {
      type: Array,
      required: false,
      default: () => [{ name: "", url: "/" }]
    },
    rightSide: {
      type: Array,
      required: false,
      default: () => [{ name: "", url: "/" }]
    }
  },
  computed: {
    fullPath() {
      return this.$route.fullPath;
    }
  }
};
</script>

<style scoped></style>
