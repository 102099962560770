<template>
  <div class="section-wrapper footer">
    <h1 id="hse">Health, Safety, &amp; Environment</h1>
    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-12 col-sm-10 p-0">
          <p class="bg-dark text-light p-5 font-weight-bold font-size-bigger">
            At Fluid Pro, the health and safety of our employees is of primary
            importance. Our safety guidelines comply with OSHA and are intended
            to promote a workplace that is safe and free of preventable
            accidents and injuries. Fluid Pro employees are encouraged and
            expected to make necessary decisions to deliver safety performance
            excellence. In addition to safety, Fluid Pro is committed to
            providing environmentally responsible solutions for our customers by
            ensuring compliance with all environmental laws, regulations, and
            industry standards.
          </p>
          <ul class="list-group-flush pl-0">
            <li class="list-group-item active">
              Our Safety Program Includes
            </li>
            <li class="list-group-item">
              A drug testing program administered by DISA which promotes a safe,
              drug and alcohol abuse free workplace that will reduce workplace
              incidents.
            </li>
            <li class="list-group-item">
              Mechanical and physical safeguards to the maximum extent possible.
            </li>
            <li class="list-group-item">
              A program of safety and health inspections to find and eliminate
              unsafe working conditions or practices, to control health hazards,
              and to fully comply with OSHA safety and health standards for
              every job.
            </li>
            <li class="list-group-item">
              Training of all staff in good safety and health practices.
            </li>
            <li class="list-group-item">
              JSA’s required at least daily in order to eliminate or control
              workplace hazard exposure.
            </li>
            <li class="list-group-item">
              A Short Service Employee (SSE) Policy to ensure that employees
              with less than six months of continuous experience are identified,
              supervised, mentored, and managed in order to prevent accidents
              and injury.
            </li>
            <li class="list-group-item">
              Providing the necessary personal protective equipment and
              instructions for proper use and care.
            </li>
            <li class="list-group-item">
              Safety and health rules, which employees are required to cooperate
              with as a condition of employment.
            </li>
            <li class="list-group-item">
              Prompt, and thorough investigations of every accident to find out
              what caused it, and correct the problem so it will not happen
              again.
            </li>
            <li class="list-group-item">
              Safeland and OSHA certification to provide proper training on the
              recognition, avoidance, and prevention of safety and health
              hazards in the workplace.
            </li>
          </ul>
        </div>
        <div class="col-12 col-sm-2">
          <div class="card w-100" style="width: 18rem;">
            <h2 class="text-center">Records</h2>
            <div class="card-header bg-dark text-light">
              2018
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">973,143 Miles Driven</li>
              <li class="list-group-item">100 Employees</li>
              <li class="list-group-item">274,000 Man Hours</li>
              <li class="list-group-item">1 Lost Time Accidents</li>
            </ul>
            <div class="card-header bg-dark text-light">
              2017
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">950,000 Miles Driven</li>
              <li class="list-group-item">75 Employees</li>
              <li class="list-group-item">196,000 Man Hours</li>
              <li class="list-group-item">0 Lost Time Accidents</li>
            </ul>
            <div class="card-header bg-dark text-light">
              2016
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">220,000 Miles Driven</li>
              <li class="list-group-item">50 Employees</li>
              <li class="list-group-item">87,500 Man Hours</li>
              <li class="list-group-item">0 Lost Time Accidents</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HSE"
};
</script>

<style scoped>
.font-size-bigger {
  font-size: 165%;
}

.bg-dark {
  background-color: rgba(173, 175, 174, 0.5) !important;
}

.list-group-item {
  background-color: rgba(34, 34, 34, 0.75);
}
</style>
