<template>
  <div class="section-wrapper">
    <h1 v-if="header && targetName" :id="targetName">{{ header }}</h1>
    <div class="card-group" v-if="items && items.length">
      <card
        v-for="(item, idx) in items"
        :key="idx"
        :bullet-header="bulletHeader"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import ProductServiceCard from "./Card";

export default {
  name: "CardList",
  components: {
    card: ProductServiceCard
  },
  props: {
    targetName: {
      type: String,
      required: true,
      default: ""
    },
    header: {
      type: String,
      require: false,
      default: ""
    },
    bulletHeader: {
      type: String,
      required: false,
      default: ""
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped></style>
