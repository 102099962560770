import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home";
import Login from "./views/Login";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: to => {
    const postion = { x: 0, y: 0 };
    if (to.hash) {
      let docElem,
        win,
        rect,
        doc,
        elem = document.getElementById(to.hash.replace("#", ""));

      if (!elem) {
        return postion;
      }

      rect = elem.getBoundingClientRect();

      // Make sure element is not hidden (display: none) or disconnected
      if (rect.width || rect.height || elem.getClientRects().length) {
        doc = elem.ownerDocument;
        win = window;
        docElem = doc.documentElement;

        return {
          y: rect.top + win.pageYOffset - docElem.clientTop - 55,
          x: rect.left + win.pageXOffset - docElem.clientLeft
        };
      }
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
});
