<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-xl fixed-top">
    <router-link class="navbar-brand" to="/">
      <img
        aria-hidden="true"
        src="../../assets/fluid-pro-icon.png"
        style="height: 25px;"
        class="d-inline-block align-top"
        alt="Fluid Pro, LLC Icon"
      />
      Fluid Pro, LLC
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#mainMenu"
      aria-controls="mainMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <links :left-side="leftLinks" :right-side="rightLinks" />
  </nav>
</template>

<script>
import Links from "@/components/nav/Links";

export default {
  name: "Top",
  data() {
    return {
      rightLinks: [
        {
          name: "Careers",
          url: "/#careers"
        },
        {
          name: "HSE Information",
          url: "/#hse"
        },
        {
          name: "Contact Us",
          url: "/#contact-us"
        }
      ],
      leftLinks: [
        {
          name: "Home",
          url: "/#top"
        },
        {
          name: "About Us",
          url: "/#about-us"
        },
        {
          name: "Services",
          url: "/#services"
        },
        {
          name: "Rentals",
          url: "/#rentals"
        }
      ]
    };
  },
  components: {
    Links
  }
};
</script>

<style scoped></style>
