<template>
  <div class="home-wrapper">
    <div class="intro" aria-hidden="true">
      <img
          src="../assets/FluidPro-LLC-Logo.png"
          alt="Fluid Pro, LLC Official Logo"
      />
    </div>
    <single-column header="About Us" target-name="about-us" :rows="aboutUs"/>
    <card-list
        header="Services"
        target-name="services"
        bullet-header="Common Equipment"
        :items="services.main"
    />
    <card-list
        header="Rentals"
        target-name="rentals"
        bullet-header="Highlights"
        :items="rentals.main"
    />
    <hse/>
    <div class="section-wrapper footer">
      <h1 id="contact-us">Contact Us</h1>
      <div class="container-fluid">
        <div class="card-deck">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title">Main Office</h2>
              <div class="d-inline-block p-5">
                <h3>Mailing Address</h3>
                <p>
                  Fluid Pro, LLC
                  <br>
                  PO BOX 473
                  <br>
                  Riverton, WY 82501
                </p>
              </div>
              <div class="d-inline-block p-5">
                <h3>Physical Address</h3>
                <p>
                  Fluid Pro, LLC
                  <br>
                  140 Linden Lane
                  <br>
                  Riverton, WY 82501
                </p>
              </div>
              <div class="d-inline-block p-5">
                <h3>Phone</h3>
                <p>
                  (307) 855-7704
                  <br> &nbsp; <br> &nbsp;
                </p>
              </div>
              <div class="d-inline-block p-5">
                <h3>Fax</h3>
                <p>
                  (855) 851-4989
                  <br> &nbsp; <br> &nbsp;
                </p>
              </div>
              <div class="d-inline-block p-5">
                  <a
                    href="https://app.connecting.cigna.com/e/er?s=1399&lid=18561&elqTrackId=de349e7af56c46bca637e8b8ce62f765&elq=e53ce39c56b14cbbb04cc6ea31789189&elqaid=24012&elqat=1">MFRs</a>
                <p>
                  This link leads to the machine-readable files that are made available in response to the federal
                  Transparency in Coverage Rule and includes negotiated service rates and out-of-network allowed amounts
                  between health plans and healthcare providers. The machine readable files are formatted to allow
                  researchers, regulators, and application developers to more easily access and analyze data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import SingleColumn from '@/components/SingleColumn';
import CardList from '@/components/CardList';
import HSE from '@/components/HSE';

export default {
  name: 'Home',
  components: {
    CardList,
    SingleColumn,
    hse: HSE
  },
  data() {
    return {
      aboutUs: [
        {
          header: 'Our Mission',
          content: [
            `At Fluid Pro, our mission statement is to provide high-quality equipment
              and first-class service to our customers in a safe and efficient manner.`
          ]
        },
        {
          header: 'Our Team',
          content: [
            `We realize that the success of our company is closely related to our
            service and reputation in the oilfield. We hire high-quality,
            competent employees to represent our company and protect our image
            by acting with honesty, integrity and accountability. Our core crew
            has more than 200 years of combined experience in the solids control
            industry.`
          ]
        },
        {
          header: 'Health, Safety, &amp; Environment',
          content: [
            `At Fluid Pro, the health and safety of our employees is of primary
            importance. Our safety guidelines comply with OSHA and are intended
            to promote a workplace that is safe and free of preventable
            accidents and injuries. Fluid Pro employees are encouraged and
            expected to make necessary decisions to deliver safety performance
            excellence.`
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState({
      services: 'Services',
      rentals: 'Rentals'
    })
  }
};
</script>

<style lang="scss">
@import "../scss/views/Home";
</style>
