<template>
  <div id="app">
    <top-nav />
    <router-view />
  </div>
</template>

<script>
import TopNav from "./components/nav/Top";

export default {
  name: "App",
  components: {
    TopNav
  }
};
</script>

<style lang="scss">
@import "scss/main";
</style>
