<template>
  <div class="section-wrapper">
    <h1 :id="targetName">{{ header }}</h1>
    <div class="container">
      <div
        class="row"
        v-for="(row, rowIndex) in rows"
        :key="targetName + '-' + rowIndex"
      >
        <div class="col-12">
          <h2>{{ row.header }}</h2>
          <p
            v-for="(c, contentIndex) in row.content"
            :key="'content-' + rowIndex + '-' + contentIndex"
          >
            {{ c }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleColumn",
  props: {
    targetName: {
      type: String,
      required: true,
      default: ""
    },
    header: {
      type: String,
      required: false,
      default: ""
    },
    rows: {
      type: Array,
      required: true,
      default: () => [{ header: "", content: [""] }]
    }
  }
};
</script>

<style scoped></style>
